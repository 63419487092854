import React from "react"
import MainLayout from "../components/Layout/MainLayout"
import Logo from "../components/logo"
import classnames from "../helpers/classnames"
import Section from "../components/Section"
import Button from "../components/button"

const Page = ({ location }) => {

  return (
    <MainLayout>
      <div className={classnames("text-center my-32")}>
        <a href="/#" title="MINDWORX" className="logo">
          <Logo style={{ width: "90px", height: "90px" }}/>
        </a>
      </div>

      <Section
        title={<>Hi there behavioral soul!</>}
        className={classnames("m-auto pt-0 text-center")}
        style={{maxWidth: "640px"}}
      >
        <p className={"mt-32"}>We're sad to see you leave but, well, that's life. You're now unsubscribed.</p>

        <div className={"mt-32 text-center"}>
          <Button href="/">VISIT MASTERCLASS</Button>
        </div>
      </Section>
    </MainLayout>
  )
}

export default Page
